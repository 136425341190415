.spinner__container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  display: inline-block;
  /* width: 50px;
  height: 50%; */
  border: 10px solid #000;
  border-radius: 50%;
  border-top-color: var( --secondary-color);
  /* animation: spin 1s ease-in-out infinite; */

  /* position: absolute; */
  width: 300px;
  height: 200px;
  /* z-index: 15; */
  /* top: 50%; */
  height: 5rem;
  width: 5rem;

  box-sizing: border-box;
  background: transparent;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
