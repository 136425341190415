* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
:root {
  --primary--color: #000;
  --secondary-color: #fff;
  --extra-color: #878788;
  --button-color: #1b1c1d;
  --bg-color: rgba(255, 255, 255, 0.5);
  --p-color: #868593;
  --off-white: #d6d6d6;
  --p-white: #838383;
  --off-color: #00000029;
  --other-color: rgba(0, 0, 0, 0.3);
  --nav-color: rgba(0, 0, 0, 0.2);
  --menu-color: #ffffff;
  /* --menu-color: rgb(252, 252, 252); */
  --sub-color: #ccc;
  --hover-color: rgb(219, 219, 219);
  --wrapper-color: #efefef;
  --slider-color: rgb(134, 133, 147);
  --video-color: #f6f6f6;
  --header-color: rgb(189, 189, 202);
  --main-white: #ffffff;
  --main-grey: #6f6f6f;
  --border-color: #232323;
  --grey-color: #a8a8a8;
  --border-border: #d3d3d3;
}
/* Browser Default Reset */

/* h1,
h2,
h3,
h4,
h5,
h6 {
  overflow: hidden !important;
} */

@font-face {
  font-family: "Airbnb Cereal App Book";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Book"),
    url("./assets/fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBook.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Light";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Light"),
    url("./assets/fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealLight.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Medium"),
    url("./assets/fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealMedium.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Bold"),
    url("./assets/fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Extra Bold"),
    url("./assets/fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealExtraBold.woff")
      format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Black";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Black"),
    url("./assets/fonts/airbnb-cereal-app-cufonfonts-webfont/AirbnbCerealBlack.woff")
      format("woff");
}

/* html {
  font-size: 62.5%;
  scroll-behavior: smooth;
} */

ul{
  padding: 0px;
}
html {
  font-size: 90%; /* You can adjust this value based on your design preferences */
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body{overflow-x: hidden;}
a,
a:visited,
a:focus,
a:active{
  text-decoration: none;
  white-space: nowrap;
  cursor:pointer;
}

:root {
  --type-scale: 0.85;
}
.App {
  color: var(--theme-page-text);
  background: var(--theme-page-bg);
  min-height: 100vh;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}

.dark-mode {
  --theme-page-text: #fdfdfd;
  --theme-page-bg: #222426;
}

.light {
  --theme-page-text: #1b1c1d;
  --theme-page-bg: #ffffff;
}


/* Utils */
.general__container {
  width: 95%;
  margin: 0 auto;
  padding: 0 1.5rem;
}

button {
  display: inline-block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--button-color);
  font-weight: medium;
  font-family: "Airbnb Cereal App Medium";
}

/* Navigation */

.nav-bar {
  display: flex;
  align-items: center;
  overflow: hidden; /* Hide scrollbars */
  height: 9vh;
  position: fixed;
  width: 100%;
  z-index: 2000;
  background-color: #fff;
  -webkit-box-shadow: 0 0.3rem 0.7rem var(--nav-color);
  -moz-box-shadow: 0 0.3rem 0.7rem var(--nav-color);
  -o-box-shadow: 0 0.3rem 0.7rem var(--nav-color);
  box-shadow: 0 0.3rem 0.7rem var(--nav-color);
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-list,
.other-links {
  display: none;
  align-items: center;
  list-style: none;
  margin-bottom: 0px !important;
}

.nav-item {
  _padding: 0 2rem;
}
.nav-item a {
  text-decoration: none;
}

.nav-header {
  position: relative;
  z-index: 15;
}

.nav-header .img {
  margin-top: -5px;
}

.nav-link {
  font-size: calc(1.6rem * var(--type-scale));
  line-height: calc(2.2rem * var(--type-scale));
  color: var(--button-color);
  padding: 2rem 1.8rem;
  cursor: pointer;
  font: normal normal medium Airbnb Cereal App Bold;
}

.nav-link:hover,
.nav-link:visited,
.nav-link:active,
.nav-link:focus {
  color: var(--button-color) !important;
}
.app__link {
  margin-bottom: 0 !important;
}

.user-link {
  display: flex;
  align-items: center;
}

.user-link img {
  margin-left: 1rem;
}

.bg-toggle-btn img {
  width: 2.93rem;
  height: 2.93rem;
}

/* Hamburger icon */

.sidebar-toggle {
  position: relative;
  z-index: 15;
}

svg .a {
  fill: none;
  stroke: var(--button-color);
  stroke-linecap: round;
  stroke-width: 2px;
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
}

/* Submenu */

.submenu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  display: none;
  border: 0.1px solid var(--sub-color);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  background: var(--menu-color);
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  z-index: 5;
  overflow: hidden !important;
  border-radius: 10px;
}

.show-submenu {
  display: block;
  opacity: 1;
  visibility: visible;
  overflow: hidden !important;
}

.submenu article {
  background: var(--menu-color);
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  overflow: hidden !important;
}

.submenu a {
  display: inline-block;
  text-decoration: none;
  padding: 2rem;
  color: var(--primary--color);
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  overflow: hidden !important;
}

.submenu article:hover {
  background: var(--hover-color);
}

.submenu a p {
  color: var(--p-color);
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Book";
  overflow: hidden !important;
}
.submenu a h2{
color: var(--button-color);
font-size: 1rem;
font-family: "Airbnb Cereal App Medium";
}

.submenu a:hover {
  transform: translateY(0.5rem);
  -webkit-transform: translateY(0.5rem);
  -moz-transform: translateY(0.5rem);
}

/* Aside */

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(105%);
  -webkit-transform: translateX(105%);
  -moz-transform: translateX(105%);
  background-color: var(--secondary-color);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  text-align: center;
  padding: 2rem;
  box-shadow: -0.2rem 0 0.7rem rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -0.2rem 0 0.7rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -0.2rem 0 0.7rem rgba(0, 0, 0, 0.2);
  transition: transform 0.6s ease-in-out;
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  z-index: 10;
  overflow-y: scroll;
}

.show-sidebar {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
}

.close-btn {
  justify-self: flex-end;
}

.sidebar-content h4 {
  color: var(--extra-color);
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
}

.sidebar-link,
.social-link {
  font-size: 1rem;
  font-family: "Airbnb Cereal App Bold";
  text-decoration: none;
  color: var(--button-color);
  padding: 1rem 0;
}
.sidebar-list h2,
.social-list h3{
  font-size: 1rem;
  color: var(--p-color)
}
.sidebar-link:hover{
  color: var(--button-color);
}

.sidebar-link {
  position: relative;
  overflow-y: hidden;
  display: block;
  transition: transform 0.4s ease-in-out;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
}

.sidebar-link span {
  display: inline-block;
  transition: transform 0.4s ease-in-out;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
}

.sidebar-link:hover span {
  transform: translateY(-150%);
  -webkit-transform: translateY(-150%);
  -moz-transform: translateY(-150%);
}

.sidebar-link::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(150%);
  -webkit-transform: translateY(150%);
  -moz-transform: translateY(150%);
  transition: transform 0.4s 0.1s ease-in-out;
  -webkit-transition: transform 0.4s 0.1s ease-in-out;
  -moz-transition: transform 0.4s 0.1s ease-in-out;
}

.sidebar-link:hover::before {
  content: attr(data-hover);
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.social__images {
  width: 50px;
  height: 50px;
  margin: 0 10px;
}
.social__images img {
  width: 100%;
  height: 100%;
}

.social-link {
  padding: 0 1rem;
}

/* Slider */

.slider-wrapper {
  margin-top: 15rem;
  padding: 2rem 0;
  background: var(--wrapper-color);
  margin-bottom: 15rem !important;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  overflow: hidden;
}
.slider-image {
  width: 100%;
  /* height: 139px; */
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  transition: all 0.4s ease-out;
  padding: 35px 20px 0px;
}
.slider-image:hover {
  background-color: #fdfdfd;
  border-radius: 15px;
}
.slider-image .hottest_rates {
  display: inline-block;
  font-size: 0.625rem;
  font-family: Airbnb Cereal App Book;
  color: var(--button-color);
  padding: 4px 7px;
  background-color: #dadada;
  border-radius: 14px;
  margin-bottom: 0.5rem;
}
.slider-image h3 {
  font-size: 0.875rem;
  color: var(--p-color);
  text-align: center;
  font-family: "Airbnb Cereal App Medium";
}

.slider-image .rates {
  color: var(--button-color);
  font-family: "Airbnb Cereal App Bold";
  font-size: 0.875rem;
}
.angle__right_hottest_rates {
  font-size: 0.875rem;
  font-family: "Airbnb Cereal App Medium";
}
.angle__right_hottest_rates:hover {
  text-decoration: underline;
}

/* Slider */
.slick-slider {
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  background: none;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  transition: all 0.4s ease-out;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}
.slider-image_container {
  width: 89px;
  height: 58px;
  margin-bottom: 5px;
}
.slider-image-image {
  height: 100%;
  width: 100%;
}

.slick-slide img {
  display: block;
}


.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: -6rem;
  display: block;
  margin: auto 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0;
  -webkit-transform: translate(0, -90%);
  -ms-transform: translate(0, -90%);
  transform: translate(0, -90%);
  cursor: pointer;
  color: var(--primary--color);
  border: none;
  outline: none;
  background: var(--secondary-color);
  -webkit-box-shadow: 0px 3px 6px var(--off-color);
  -moz-box-shadow: 0px 3px 6px var(--off-color);
  box-shadow: 0px 3px 6px var(--off-color);
  z-index: 5;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: var(--primary--color);
  outline: none;
  background-color: var(--bg-color);
  -webkit-box-shadow: 0 0 0.5rem var(--other-color);
  -moz-box-shadow: 0 0 0.5rem var(--other-color);
  box-shadow: 0 0 0.5rem var(--other-color);
}

.slick-prev {
  left: 2rem;
  background-image: url(./assets/Img/ic_keyboard_arrow_right_24px.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.slick-next {
  left: 6rem;
  background-image: url(./assets/Img/ic_keyboard_arrow_right_24px-1.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: var(--primary--color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: var(--primary--color);
}

.slide-extra {
  position: absolute;
  bottom: -3.8rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  white-space: nowrap !important;
  color: var(--button-color);
}
.slide-extra:hover,
.slide-extra:visited,
.slide-extra:active,
.slide-extra:focus{
  color: var(--button-color);
}

.angle__right {
  display: flex;
  height: 10px;
  width: 7px;
  margin-top: -5px;
}
.angle__right img {
  width: 100%;
  height: 100%;
}

.lottie__animation__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lottie_animation__container__view {
  height: 80px;
  width: 80px;
}


@media only screen and (min-width: 985px) {
  :root {
    --type-scale: 1;
  }

  .nav-list,
  .other-links {
    display: flex;
  }
}

@media (min-width: 1023px) and (max-width: 1200px) {
}

@media only screen and (min-width: 600px) {
  :root {
    --type-scale: 0.9;
  }

  .general__container {
    max-width: 90%;
  }

  .sidebar {
    width: 60rem;
  }

  .slick-next,
  .slick-prev {
    top: 5%;
    bottom: auto;
    -webkit-transform: translate(0, -15%);
    -moz-transform: translate(0, -15%);
    -ms-transform: translate(0, -15%);
    transform: translate(0, -15%);
  }

  .slick-next {
    right: 2rem;
    left: auto;
  }

  .slick-prev {
    right: 6rem;
    left: auto;
  }

  .slide-extra {
    bottom: 1%;
  }

}

@media only screen and (min-width: 985px) {
  :root {
    --type-scale: 1;
  }

  .nav-header {
    padding-right: 2rem;
    position: relative;
  }

  .nav-header::after {
    height: 30%;
    width: 1px;
    background: #707070;
    content: "";
    position: absolute;
    right: 0;
    top: 3.5rem;
  }

  .nav-list,
  .other-links {
    display: flex;
  }
}

@media (min-width: 768px) {
  .slider-image_container {
    width: 106px;
    height: 70px;
    margin-bottom: 5px;
  }
  .slider-image {
    width: 100%;
    height: 241px;
  }

  .slider-image h3 {
    font-size: 1.25rem;
    white-space: nowrap;
  }
  .slider-image .rates {
    font-size: 1.25rem;
  }
  .angle__right_hottest_rates {
    font-size: 1.125rem;
  }
  .lottie__animation__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lottie_animation__container__view {
    height: 162px;
    width: 162px;
  }

  .general__container {
    width: 80%;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
  .sidebar-link,
  .social-link,
  .sidebar-list h2,
  .social-list h3 {
    font-size: 1.2rem;
  }
}


@media (min-width: 481px) and (max-width: 767px) {
}

@media (min-width: 320px) and (max-width: 480px) {
}
@media (max-width: 319px) {
}
